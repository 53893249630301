import React from 'react';
import { FrontPageHarness } from '../fdi/FrontPages/FrontPagesCommon';
import { Typography } from '@mui/material';
import { useTeamFeedback } from './useTeamFeedback';
import { TeamFeedbackForm } from './RoundsFeedback';

interface Props {
  
}


export const TeamFeedbackPage = (props: Props) => {
  const data = useTeamFeedback();

  return (
    <FrontPageHarness
      title={data.pageConfig.data.messages?.common?.title}
      subtitle={data.pageConfig.data.messages?.common?.subtitle}
      pageConfig={data.pageConfig.customBlocksData.page_config}>

      {!data.isAvailable && <Typography style={{ whiteSpace: "pre-line", textAlign: "center" }}>
        {data.pageConfig.customBlocksData.page_config?.unavailable_message}
      </Typography>}

      {data.isMissingArguments && <Typography style={{ whiteSpace: "pre-line", textAlign: "center" }}>
        {data.pageConfig.customBlocksData.page_config?.missing_arguments_message}
      </Typography>}

      {data.isAvailable && <TeamFeedbackForm messages={data.pageConfig.customBlocksData.page_config} data={data.data} />}
    </FrontPageHarness>
  );
}
