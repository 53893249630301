import React from 'react';
import styled from '@emotion/styled';

const LogoTag = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
    border-radius: 50%;
`;

export const Logo = (props: {version?: 1 | 2}) => (
    <LogoTag src="/img/logo_on_w.png" alt="FDI" />
);