import { useState } from "react";
import { apiFetch } from "../../../../toolympus/api/core";
import { useEditItem2 } from "../../../../toolympus/api/useNewItem"
import { generateCode } from "../../../../toolympus/components/PowerDoc/plugins/common";
import { useEditArray } from "../../../../toolympus/hooks/useEditArray";
import { Team } from "./types";
import { FieldType, Schema } from "../../../../toolympus/hooks/useSchema";
import { useCmsPage } from "../../../../toolympus/components/CMS/Usage";
import { ContestState } from "../../../ContestControl";


export interface TeamRegistration extends Partial<Pick<Team, "email" | "phone" | "university" | "country" | "timezone" | "register_for_conference" | "previously_participated" | "pdc" | "coaches_as_arbitrators_consent" | "members" | "coaches">> {

}

export const PersonSchema: Schema = {
  full_name: { label: "Full Name" },
  is_primary_contact: { label: "Primary Contact", type: FieldType.bool },
}

export const useTeamSignup = () => {
  const [isSuccess,setIsSuccess] = useState<boolean>(false);
  const data = useEditItem2<TeamRegistration>({
    getApiPath: () => "",
    save: (team) => {
      const signupTeam = { ...team };
      return apiFetch<{ }>("/api/team/signup", "POST", signupTeam)
        .then(() => {
          setIsSuccess(true);
          return team;
        })
    },
    startWith: {
      email: "",
      phone: "",
      country: "",
      university: "",
      members: [{ _id: generateCode(), full_name: "", is_primary_contact: true }],
      coaches: [],
    },
    dontResetOnSave: true,
  });


  const members = useEditArray({
    items: data.item?.members || [],
    update: members => data.update({ members }),
    createDflt: () => ({ full_name: "", _id: generateCode() }),
    dflt: { full_name: "" },
  });

  const coaches = useEditArray({
    items: data.item?.coaches || [],
    update: coaches => data.update({ coaches }),
    createDflt: () => ({ full_name: "", _id: generateCode() }),
    dflt: { full_name: "" },
  });

  const updatePrimaryContact = (personId: string, value: boolean) => {
    if(!value) {
      return;
    }

    data.update({
      members: (data.item?.members || []).map(p => p._id === personId ? { ...p, is_primary_contact: value } : { ...p, is_primary_contact: false}),
      coaches: (data.item?.coaches || []).map(p => p._id === personId ? { ...p, is_primary_contact: value } : { ...p, is_primary_contact: false}),
    })
  }

  const pageConfig = useCmsPage<{ messages: Record<string, any>, contest_state: ContestState }>("/api/public/cms/platform_page", "team-registration");

  const schema: Schema = (pageConfig.data as any).schema_team || {};

  return {
    ...data,
    pageConfig,
    members,
    coaches,
    updatePrimaryContact,
    isSuccess,
    schema,
  }
}
