import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from "../../theme";
import { TeamRoundsFeedback } from '../../toolympus/components/Contests/Grid/feedback.types';
import { Typography } from '@mui/material';
import { useFormats } from '../../toolympus/components/schemed';
import { RoundScoresTable } from './RoundScoresTable';
import { RoundScoresTablePhones } from './RoundScoresTablePhones';

export const RoundWrapper = styled.div`
  & .round-title {
    margin: 2rem 0 1rem;

    & .time {
      font-weight: 400;
      font-size: 0.8rem;
      color: ${ThemeSettings.colors.text};
    }

  }

  @media print {
    break-inside: avoid;
  }
`;


export const TeamFeedbackForm = (props: { messages: Record<string, string>, data: TeamRoundsFeedback}) => {
  const { messages, data } = props;
  const { formatDatetime } = useFormats();

  return (
    <>
      <Typography variant="h3">{messages?.feedback_label || "Scoring sheets"}</Typography>
      {messages?.feedback_guide &&
        <Typography style={{ whiteSpace: "pre-line" }}>{messages?.feedback_guide}</Typography>}
      {(data.feedbacks || []).map(roundFeedback => (
        <RoundWrapper key={roundFeedback.round_id}>
          <Typography variant="h4" className="round-title">
            {roundFeedback.round_title}{roundFeedback.round_subtitle ? ". " : ""}{roundFeedback.round_subtitle || ""} <span className="time">{roundFeedback.round_time ? formatDatetime(roundFeedback.round_time) : ""}</span>
          </Typography>
          
          <RoundScoresTable scores={roundFeedback} config={data.config.sheet} />
          <RoundScoresTablePhones scores={roundFeedback} config={data.config.sheet} />
        </RoundWrapper>
      ))}
    </>
  )
}