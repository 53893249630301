import React from "react";
import { Archive, GroupOutlined, InfoOutlined, PersonOutlined, Settings, SportsHockey, SportsSoccer } from "@mui/icons-material";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { FDICMSProps } from "./components/CMS";
import { ContestControlPage } from "./components/ContestControl";
import { AdminTeamListPage } from "./components/Admin/TeamList";
import { AdminArbitratorListPage } from "./components/Admin/ArbitratorsList";
import { GridPage } from "./components/Grid";
import { Documentation } from "./components/Admin/Documentation";
import { SnapshotsManagement } from "./components/ContestControl/SnapshotsManagement";

const InnerRoutes: MenuRoute[] = [

    {
        path: "/contest",
        title: "Contest",
        icon: <SportsHockey />,
        component: ContestControlPage,
        resolveHidden: roles => (roles || []).includes("admin"),
    },
    {
        path: "/admin/teams",
        title: "Teams",
        icon: <GroupOutlined />,
        component: AdminTeamListPage,
        resolveHidden: roles => (roles || []).includes("admin"),
    },
    {
        path: "/admin/arbitrators",
        title: "Arbitrators",
        icon: <PersonOutlined />,
        component: AdminArbitratorListPage,
        resolveHidden: roles => (roles || []).includes("admin"),
    },
    { 
        icon: <SportsSoccer />,
        path: '/admin/rounds',
        title: 'Rounds',
        component: GridPage,
    },

    { 
        path: '/system',
        title: 'System',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                notificationTasks={{ apiPath: "/api/notifications/task", emailsApiPath: "/api/emails", emailsPagePath: "/system/emails" }}
                users={{ userRoles: Roles, allowCreateUsers: true, allowPaswordReset: true, allowPaswordChange: true }}
                messages={{}}
                scoringSheets={{ apiPath: "/api/scoring/sheet" }}
                queryConsole={{}}
                cms={FDICMSProps}
                extraItems={[
                  {
                    key: "snapshots",
                    label: "Snapshots",
                    icon: <Archive />,
                    items: [{ key: "snapshots", label: "", component: SnapshotsManagement }]
                  },
                  {
                    key: "documentation",
                    label: "User manual",
                    icon: <InfoOutlined />,
                    items: [{ key: "documentation", label: "", component: Documentation }],
                  }
                ]}
            />),
    },
];

export default InnerRoutes;