import { apiFetch } from "../../../toolympus/api/core";
import { useAction, useActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { generateCode } from "../../../toolympus/components/PowerDoc/plugins/common";
import { useTags } from "../../../toolympus/components/Tags";
import { useTabsState } from "../../../toolympus/components/primitives";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useEditArray } from "../../../toolympus/hooks/useEditArray";
import { useFieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { useLoadedData, urlWithQuery } from "../../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { Team } from "../../fdi/FrontPages/TeamSignup";
import { exportTeamsXLSX } from "./teamsExport";

const ApiPath = "/api/team/admin";

export const useTeamsList = () => {
  const tabs = useTabsState([
    ["all", "All"],
    ["not-excluded", "Active"],
    ["excluded", "Excluded"],
  ], "not-excluded", "tab", "__fdi_team_list_cur_tab");

  const sorting = useFieldSorting({
    allowedFields: [
      "codename",
      "country",
      "timezone",
      "register_for_conference",
      "previously_participated",
      "university",
      "email",
      "created_at",
    ],
  })
  
  const data = useLoadedData<Team[]>(urlWithQuery(ApiPath, { view: tabs.current, "order-by": sorting.sort ? `${sorting.sort.field}:${sorting.sort.direction}` : undefined }), []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const filter = useTextFilter<Team>(t => `${t.codename} ${t.email} ${t.university} ${t.country} ${t._id}`)
  const filteredData = filter.filterData(data.data);

  const review = useEditItem2<Team>({
    save: (item,changes) => apiFetch<Team>(`${ApiPath}/${item._id}`, "put", changes)
      .then(t => {
        data.reload();
        return t;
      }),
    dontResetOnSave: true,
  });


  const members = useEditArray({
    items: review.item?.members || [],
    update: members => review.update({ members }),
    createDflt: () => ({ full_name: "", _id: generateCode() }),
    dflt: { full_name: "" },
  });

  const coaches = useEditArray({
    items: review.item?.coaches || [],
    update: coaches => review.update({ coaches }),
    createDflt: () => ({ full_name: "", _id: generateCode() }),
    dflt: { full_name: "" },
  });

  const updatePrimaryContact = (personId: string, value: boolean) => {
    if(!value) {
      return;
    }

    review.update({
      members: (review.item?.members || []).map(p => p._id === personId ? { ...p, is_primary_contact: value } : { ...p, is_primary_contact: false}),
      coaches: (review.item?.coaches || []).map(p => p._id === personId ? { ...p, is_primary_contact: value } : { ...p, is_primary_contact: false}),
    })
  }



  const exclude = useAction(() => {
    if(!review.item) {
      return Promise.resolve({});
    } else {
      return apiFetch<Team>(`${ApiPath}/${review.item._id}`, "put", { is_excluded: !review.item.is_excluded})
        .then(x => {
          review.setItem(x);
          data.reload();
          return x;
        })
    }
  });

  const remove = useActionWithConfirmation(() => {
    if(!review.item) {
      return Promise.resolve({});
    } else {
      return apiFetch<Team>(`${ApiPath}/${review.item._id}`, "delete")
        .then(x => {
          review.cancel();
          data.reload();
          return x;
        })
    }
  }, {
    title: "Remove team"
  })

  const assignCodenames = useEditItem2<{ names: string }>({
    save: item => {
      const codenames_pool = (item.names || "").split("\n").map(n => n.trim()).filter(x => !!x);

      return apiFetch<{}>(`${ApiPath}/assign-codenames`, "post", { codenames_pool })
        .then(() => {
          data.reload();
          return item;
        })
    }
  });

  const conflicts = useTags("/api/conflict/tag", "team");

  const exportTeams = () => exportTeamsXLSX(data.data, schema, `FDI teams ${new Date().getFullYear()}.xlsx`);

  return {
    ...data,
    data: filteredData,
    schema,
    filter,
    tabs,
    sorting,
    review: {
      ...review,
      members,
      coaches,
      updatePrimaryContact,
    },
    exclude,
    remove,
    assignCodenames,
    conflicts,
    exportTeams,
  }
}
