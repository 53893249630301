import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { GlobalStyles } from './components/Common/GlobalStyles';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';
import { ActionsConfigProvider } from './toolympus/components/Actions/ActionsConfigProvider';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="en" defaultLocale="en">
                    <FormatsProvider formats={{ date: "DD.MM.YYYY" }}>
                      <ConfirmationDialogProvider>
                        <ApiConfgurationProvider
                          actionsApiPath="/api/actions"
                          emailTemplatesApiPath="/api/emails">
                          <ActionsConfigProvider>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                              <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                  <GlobalStyles />
                                  {props.children}
                              </DatesLocalizationProvider>
                            </SnackbarProvider>
                          </ActionsConfigProvider>
                        </ApiConfgurationProvider>
                      </ConfirmationDialogProvider>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
