import React from 'react';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { ActionRow, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { ContestStateEditData } from './useContestStateEdit';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props {
  data: ContestStateEditData;
}

export const ContestStateForm = (props: Props) => {
    const data = props.data;

    return (<>
      <ActionRow>
        <OccupyFreeSpace />
        <IconButton size="small" style={{ visibility: "hidden" }}><SaveOutlined /></IconButton>
        {data.isLoading && <LoadingIndicator />}
        {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
      </ActionRow>

      <FormGrid columns="1fr">
        <FormControlsForFields
          data={data.settings}
          onChange={(o,c) => data.update(c)}
          schema={data.schema}
          fields={[
            ["team_registration_allowed"],
            ["arbitrator_registration_allowed"],
          ]}
          />
      </FormGrid>
      </>);
}
