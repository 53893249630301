import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../../theme';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { processBasicExtraLinks } from '../../../toolympus/components/CMS/Usage';

export const FrontPageImage = styled.img`
  position: fixed;
  z-index: 100;
  width: 30%;
  height: 100svh;
  top: 0;
  left: 0;
  background: url(/img/spb2.png);
`;

export const FrontPageImageHolder = styled.div`
  position: fixed;
  z-index: 100;
  width: 40%;
  height: 100svh;
  top: 0;
  left: 0;
  background-color: ${ThemeSettings.colors.backgroundAlt1};

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(/img/spb2.png);
    background-position: center right;
    background-size: cover;
    display: block;
    content: ' ';
  }

  @media ${ThemeSettings.breakpoints.sm.down} {
    width: 20px;
    &::after {
      background-position: 71% 50%;
    }
  }

  @media print {
    display: none;
  }
`;

export const FrontPageLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 0.8rem;

  & a {
    color: ${ThemeSettings.colors.primary};
    border-bottom: 1px dotted ${ThemeSettings.colors.primary};
    text-decoration: none;
  }

  @media print {
    display: none;
  }
`;

export const FrontPageContent = styled.div<{ noSpread?: boolean }>`
  background: ${ThemeSettings.colors.background};
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100%;
  width: 60%;
  padding: 100px 100px;
  box-sizing: border-box;
  box-shadow: ${ThemeSettings.sizes.shadow.panel} ${ThemeSettings.colors.shadow.panel};

  display: flex;
  flex-flow: column;
  justify-content: ${props => props.noSpread ? "flex-start" : "space-between"};
  gap: 30px;

  & h1, & h1 + p {
    white-space: pre-line;
  }

  & ${FrontPageLinks} + h1 {
    margin-top: 0.5rem;
  }

  @media ${ThemeSettings.breakpoints.sm.down} {
    width: calc(100% - 20px);
    padding: 20px 15px 20px 10px;
  }

  

  @media print {
    width: 100%;
    padding: 10px 10px;
    box-shadow: none;
  }
  
`;

export const FrontPageWrapper = styled.div`
  min-height: 100svh;
  box-sizing: border-box;

  & > * {
    z-index: 200;
  }
`;

export const FrontPageForm = styled.form`
  min-width: 300px;

  & h2 {
    margin-top: 0;
  }
`;


interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  pageConfig?: {
    header_links?: string;
    footer_links?: string;
  };
  noSpreadContent?: boolean;
}

export const FrontPageHarness = (props: PropsWithChildren<Props>) => {
  const [headerLinks, footerLinks] = useMemo(() => {
    return [
      processBasicExtraLinks(props.pageConfig?.header_links || ""),
      processBasicExtraLinks(props.pageConfig?.footer_links || ""),
    ];
  }, [props.pageConfig]);

  return (
    <FrontPageWrapper>
      <FrontPageImageHolder />

      <FrontPageContent noSpread={props.noSpreadContent}>
        <div>
          <FrontPageLinks>
            {headerLinks
              .map(([label,link]) => link.startsWith("https")
                ? <a href={link} target="_blank" rel="noreferrer noopener">{label}</a>
                : <Link to={link}>{label}</Link>)}
          </FrontPageLinks>

          <Typography variant="h1">{props.title || "..."}</Typography>
          {props.subtitle && <Typography>{props.subtitle}</Typography>}
        </div>
        
        {props.children}

        <FrontPageLinks>
          {footerLinks
            .map(([label,link]) => link.startsWith("https")
              ? <a href={link} target="_blank" rel="noreferrer noopener">{label}</a>
              : <Link to={link}>{label}</Link>)}
        </FrontPageLinks>
      </FrontPageContent>
    </FrontPageWrapper>
  );
}
