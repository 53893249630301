import React from 'react';
import { apiFetch, FetchTypes } from '../../toolympus/api/core';
import { TagManagementForm, useTagManagement } from '../../toolympus/components/Tags';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';
import { Button } from '@mui/material';

const useConflictTagManagement = () => {
    const data = useTagManagement('/api/conflict/tag');

    const doPrefill = () => {
        return apiFetch('/api/conflict/prefill', FetchTypes.POST)
            .then(() => data.reload());
    }

    const prefill = useActionWithConfirmation(doPrefill, {
      title: "Collect team conflict tags",
      confirmationHint: "This will create new tags for teams. Arbitrators are tagged upon registration",
    })

    return {
        ...data,
        prefill,
    }
}

export const ConflictTagManagement = () => {
    const data = useConflictTagManagement();

    return (
        <TagManagementForm
          data={data}
          extraActions={<Button size="small" onClick={() => data.prefill.run()}>collect tags</Button>}
          />
    );
}
