import { downloadBuffer } from "../../../toolympus/api/core";
import { utc } from "../../../toolympus/components/timezone";
import { Schema } from "../../../toolympus/hooks/useSchema";
import ExcelJS from "exceljs";
import { Arbitrator } from "../../fdi/FrontPages/ArbitratorSignupForm";

export const exportArbitratorsXLSX = (arbitrators: Arbitrator[], schema: Schema, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Teams");

  sheet.columns = [
    "_id",
    "email",
    "phone",
    "full_name",
    "country",
    "timezone",
    "company",
    "position",
    "affiliation_universities",
    "affiliation_teams",
    "moot_experience",
    "comments",
    "timeslots_preference",
    "register_for_conference",
    "pdc",
    "is_excluded",
    "access_code",
    "created_at",
  ].map(key => ({ key, header: schema[key]?.label?.toString() || key }));

  arbitrators.forEach(arbitrator => {
      const { created_at, is_excluded, timeslots_preference, ...other } = arbitrator;
      sheet.addRow({
          ...other,
          timeslots_preference: timeslots_preference && Object.values(timeslots_preference).filter(x => !!x).length > 0 ? "provided" : "-",
          is_excluded: is_excluded ? "yes" : "no",
          created_at: utc.toLocal(created_at).toDate(),
      })
  });

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
}
