import React from "react";
import { Global, css } from "@emotion/react";
import { ThemeSettings } from "../../theme";
import { createGlobalStyle } from "../../toolympus/components/primitives/BaseThemeSettings";

const globalStyle = css`
  ${createGlobalStyle(ThemeSettings)}
  
  * ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }
  * ::-webkit-scrollbar-thumb {
      background-color: ${ThemeSettings.colors.primary};
  }
`;

export const GlobalStyles = () => <Global styles={globalStyle} />
