import { useSettings } from "../../toolympus/api/useSettings"
export interface ContestState {
  team_registration_allowed?: boolean;
  arbitrator_registration_allowed?: boolean;
  public_results_available?: boolean;
  timeslots?: any;
}


const DefaultContestState: ContestState = {
  team_registration_allowed: false,
  arbitrator_registration_allowed: false,
  timeslots: [],
}

export const useContestStateEdit = () => {
    const data = useSettings<ContestState>("/api/contest-state", DefaultContestState);

    return {
      ...data,
    }
}

export type ContestStateEditData = ReturnType<typeof useContestStateEdit>;
