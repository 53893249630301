import { useEditItem2 } from "../../../../toolympus/api/useNewItem";
import { useCmsPage } from "../../../../toolympus/components/CMS/Usage";
import { ContestState } from "../../../ContestControl";
import { apiFetch } from "../../../../toolympus/api/core";

export const useArbitratorTimeSlotsPage = () => {
  const pageConfig = useCmsPage<{ messages: Record<string, any>, contest_state: ContestState }>("/api/public/cms/platform_page", "arbitrator-timeslots");

  const data = useEditItem2<{ timeslots_preference: string[] }>({
    dontResetOnSave: true,
    startWith: { timeslots_preference: [] },
    save: item => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");
      const arbitratorId = query.get("id");

      if(!code || !arbitratorId) {
        return Promise.reject({ response: { data: { error_code: "error.noRights" }}});
      }

      return apiFetch(`/api/arbitrator/${arbitratorId}/timeslots?code=${code}`, "put", item)
        .then(() => item);
    }
  });

  return {
    isLoading: pageConfig.isLoading,
    pageConfig,
    data,
  }
}
