import React from "react"
import { PageViewer } from "../../../CMS/PageViewer";
import { useCmsPage } from "../../../../toolympus/components/CMS/Usage";
import { FrontPageHarness } from "../FrontPagesCommon";
import { ScoreRoundData, useScoreRoundByCode } from "../../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { useQuery } from "../../../../toolympus/hooks/useQueryParamsState";
import { createProvided } from "../../../../toolympus/components/primitives/createProvided";
import { RoundInfoBlock, RoundScoreBlock } from "../../../../toolympus/components/Contests/Grid/Parties/ScoreRoundForm";
import { SpeakerSelect } from "./SpeakerSelect";


const useScoreRound = (): ScoreRoundData | null => {
  const query = useQuery();
  return useScoreRoundByCode("/api/rounds", {
    arbiter_id: query.get("aid") || "",
    round_id: query.get("rid") || "",
    access_code: query.get("c") || "",
    });
}

const {
  Provider: RoundScoringProvider,
  useValue: useRoundScoring,
} = createProvided(useScoreRound, null);


const RoundScoreBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundScoreBlock data={data} customComponents={{ speaker: SpeakerSelect }} />
    : null;
}

const RoundInfoBlockP = () => {
  const data = useRoundScoring();
  return !!data
    ? <RoundInfoBlock data={data} />
    : null;
}


export const ScoreRoundPage = () => {
  const pageConfig = useCmsPage<{ messages: Record<string, any>}>("/api/public/cms/platform_page", "score-round");

  return (
    <RoundScoringProvider>
      <FrontPageHarness
        noSpreadContent
        title={pageConfig.data.messages?.common?.title}
        subtitle={pageConfig.data.messages?.common?.subtitle}
        pageConfig={pageConfig.customBlocksData.page_config}>

        <PageViewer
          key={pageConfig.page?.slug}
          content={pageConfig.page?.content}
          customBlocks={{
            info: RoundInfoBlockP,
            scores: RoundScoreBlockP,
          }}
          />
      </FrontPageHarness>
      
    </RoundScoringProvider>
  )
  
}