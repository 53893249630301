import { createTheme } from '@mui/material/styles';
import { BaseThemeSettings, createMuiTypographySettings } from './toolympus/components/primitives/BaseThemeSettings';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    primary: "#16A5D4",
    primaryAlt1: "#33CCFF",
    primaryAlt2: "#19C4FC",
    secondary: "#A53F59",
    secondaryAlt1: "#FA0C4A",

    background: "#ffffff",
    backgroundAlt1: "#dbf0f7",
    backgroundAlt2: "#3f6470",
    text: "#000000f0",

    shadeLight: "#000000a0",

    shadow: {
      panel: "#00000080",
    },
  },

  sizes: {
    spacing: {
      block: "1rem",
      contentBottom: { l: "30px", s: "15px" },
      contentSides: { l: "30px", s: "15px" },
    },
    shadow: {
      button: "0px 0.5px 3px -1px",
      panel: "0px 1px 6px -3px",
    },
    borderRadius: {
      panel: 8,
    },

    frontPage: {
      padding: {
        horizontal: {
          s: "20px",
          l: "100px",
        },
        vertical: {
          s: "10px",
          sExtra: "40px",
          l: "100px",
          llow: "50px",
        },
      }
    },
  },

  breakpoints: BaseThemeSettings.breakpoints,

  typography: {
    fontFamily: "Raleway",
    fontFamilyHeaders: "Raleway",
    fontSizes: BaseThemeSettings.typography.fontSizes,
  },
}

export const theme = createTheme({
    typography: createMuiTypographySettings(ThemeSettings),
    palette: {
        primary: {
            main: ThemeSettings.colors.primary,
            contrastText: "#ffffff",
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
    },
    shape: {
      borderRadius: 8,
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
      MuiButton: {
        defaultProps: BaseMuiThemeComponentsSettings?.MuiButton?.defaultProps,
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
});
