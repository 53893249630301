import React from 'react';
import { Typography, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { FormGrid, Buttons } from '../../../../toolympus/components/primitives';
import { RadioOptions } from '../../../../toolympus/components/primitives/RadioOptions';
import { FormControlsForFields, ValidationErrors } from '../../../../toolympus/components/schemed';
import { PersonSchema, TeamRegistration } from './useTeamSignup';
import { Person } from './types';
import { Schema } from '../../../../toolympus/hooks/useSchema';
import { EditArrayData } from '../../../../toolympus/hooks/useEditArray';

interface Props {
  item: TeamRegistration | null;
  update: (c: Partial<TeamRegistration>) => void;
  schema: Schema;
  errors?: ValidationErrors;
  isFormDisabled?: boolean;
  
  members: EditArrayData<Person>;
  coaches: EditArrayData<Person>;
  updatePrimaryContact: (personId: string, v: boolean) => void; 
}

export const TeamSignupForm = (props: Props) => {
  const data = props;
  const { item, update, isFormDisabled } = data;

  if(!item) {
    return null;
  }

  return (
    <>
      <FormGrid columns="1fr 1fr">
        <FormControlsForFields
          data={item}
          fields={[
            ["email", { label: "E-mail" }],
            ["phone", { label: "Phone" }],
            ["country", { label: "Country" }],
            ["timezone", { label: "Time Zone" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      <FormGrid columns="1fr" noMargin>
        <FormControlsForFields
          data={item}
          fields={[
            ["university", { label: "University" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      <FormGrid columns="1fr" noMargin>
        <RadioOptions
          value={item?.previously_participated}
          update={v => update({ previously_participated: v })}
          error={data.errors?.fieldHasErrors("previously_participated")}
          label={"Have you previously participated in the St Petersburg FDI Pre-Moot?"}
          options={(data.schema?.previously_participated?.values || []).map((v,i) => ([v.value, v.label]))}
          readOnly={isFormDisabled}
          />

        <RadioOptions
          value={item?.register_for_conference}
          update={v => update({ register_for_conference: v })}
          error={data.errors?.fieldHasErrors("register_for_conference")}
          label={"Do you wish to attend the Pre-Moot's Conference?"}
          options={(data.schema?.register_for_conference?.values || []).map((v,i) => ([v.value, v.label]))}
          readOnly={isFormDisabled}
          />
      </FormGrid>

      <Typography variant="h4" style={{ marginTop: "2rem", marginBottom: 0 }}>Team members</Typography>
      
      <FormGrid columns="1fr max-content" noMargin style={{ alignItems: "end" }}>
        {data.members.items.map((person,idx) => (
          <React.Fragment key={person._id}>
            <FormControlsForFields
              data={person}
              fields={[
                ["full_name"],
              ]}
              onChange={(o,c) => data.members.update(idx, c)}
              schema={PersonSchema}
              errors={data.errors?.getInnerErrors(["members", idx])}
              disabled={isFormDisabled}
              />
            <FormControlsForFields
              data={person}
              fields={[
                ["is_primary_contact", { controlProps: { color: "primary" }}],
              ]}
              onChange={(o,c) => data.updatePrimaryContact(person._id || "", c.is_primary_contact)}
              schema={PersonSchema}
              errors={data.errors?.getInnerErrors(["members", idx])}
              disabled={isFormDisabled}
              />
          </React.Fragment>
        ))}
      </FormGrid>

      <Buttons>
        <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.members.add()}>add a team member</Button>
      </Buttons>
      
      <Typography variant="h4" style={{ marginTop: "2rem", marginBottom: 0 }}>Coaches</Typography>

      <FormGrid columns="1fr max-content" noMargin style={{ alignItems: "end" }}>
        {data.coaches.items.map((person,idx) => (
          <React.Fragment key={person._id}>
            <FormControlsForFields
              data={person}
              fields={[
                ["full_name"],
              ]}
              onChange={(o,c) => data.coaches.update(idx, c)}
              schema={PersonSchema}
              errors={data.errors?.getInnerErrors(["coaches", idx])}
              disabled={isFormDisabled}
              />
            <FormControlsForFields
              data={person}
              fields={[
                ["is_primary_contact", { controlProps: { color: "primary" }}],
              ]}
              onChange={(o,c) => data.updatePrimaryContact(person._id || "", c.is_primary_contact)}
              schema={PersonSchema}
              errors={data.errors?.getInnerErrors(["members", idx])}
              disabled={isFormDisabled}
              />
          </React.Fragment>
        ))}
      </FormGrid>

      <Buttons>
        <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.coaches.add()}>add a coach</Button>
      </Buttons>



      <RadioOptions
        value={item?.coaches_as_arbitrators_consent}
        update={v => update({ coaches_as_arbitrators_consent: v })}
        error={data.errors?.fieldHasErrors("coaches_as_arbitrators_consent")}
        label={"We agree that the Organising Committee may appoint coaches from other teams to act as arbitrators"}
        options={(data.schema?.coaches_as_arbitrators_consent?.values || []).map((v,i) => ([v.value, v.label]))}
        readOnly={isFormDisabled}
        /> 

      <RadioOptions
        value={item?.pdc}
        update={v => update({ pdc: v })}
        error={data.errors?.fieldHasErrors("pdc")}
        label={"According to Federal Law of the Russian Federation dated 27.07.2006 No. 152-FZ \"On Personal Data\", the participants consent to processing of their personal data. (Please note that the team could not participate in the Pre-moot without consent to personal data processing)"}
        options={(data.schema?.pdc?.values || []).map((v,i) => ([v.value, v.label]))}
        readOnly={isFormDisabled}
        /> 
    </>
  );
}
