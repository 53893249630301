import { downloadBuffer } from "../../../toolympus/api/core";
import { utc } from "../../../toolympus/components/timezone";
import { Schema } from "../../../toolympus/hooks/useSchema";
import { Team } from "../../fdi/FrontPages/TeamSignup";
import ExcelJS from "exceljs";

export const exportTeamsXLSX = (teams: Team[], schema: Schema, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Teams");

  sheet.columns = [
    "_id",
    "email",
    "phone",
    "codename",
    "university",
    "country",
    "timezone",
    "register_for_conference",
    "previously_participated",
    "pdc",
    "members",
    "coaches",
    "is_excluded",
    "rounds_stage",
    "access_code",
    "created_at",
  ].map(key => ({ key, header: schema[key]?.label?.toString() || key }));

  teams.forEach(team => {
      const { created_at, members, coaches, is_excluded, ...other } = team;
      sheet.addRow({
          ...other,
          is_excluded: is_excluded ? "yes" : "no",
          created_at: utc.toLocal(created_at).toDate(),
          members: members.map(p => `${p.full_name}${p.is_primary_contact ? " (primary contact)" : ""}`).join("\n"),
          coaches: coaches.map(p => `${p.full_name}${p.is_primary_contact ? " (primary contact)" : ""}`).join("\n"),
      })
  });

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
}
