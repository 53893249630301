import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { RoundsFeedbackConfig, TeamRoundFeedback } from '../../toolympus/components/Contests/Grid/feedback.types';
import { prepareShapedScores } from './feedbackHelpers';

export const ScoresTable = styled.table`
  table-layout: fixed;
  width: 100%;
  
  & tr {
    &:nth-child(2n) {
      background: ${ThemeSettings.colors.backgroundAlt1};
    }
  }

  & th {
    font-weight: 500;
    color: ${ThemeSettings.colors.shadeLight};
  }

  & th, & td {
    text-align: center;
    padding: 8px 8px;

    &:first-child {
      text-align: left;
      border-left: 2px solid ${ThemeSettings.colors.primary}40;
      width: 300px;

      @media print {
        width: 250px;
      }
    }
    &:not(:first-child) {
      width: calc((100% - 300px) / 6);

      @media print {
        width: calc((100% - 250px) / 6);
      }
    }
    &:last-child {
      border-right: 2px solid ${ThemeSettings.colors.primary}40;
    }
    
    &:nth-child(4) {
      border-right: 2px solid ${ThemeSettings.colors.primary}40;
    }
  }

  & thead tr:nth-child(1) th:nth-child(2), & td:nth-child(1), & th:nth-child(1) {
    border-right: 2px solid ${ThemeSettings.colors.primary}40;
  }

  & thead tr, & tbody tr:last-child {
    & th, & td {
      border-bottom: 2px solid ${ThemeSettings.colors.primary}40;
      font-weight: 500;
      color: ${ThemeSettings.colors.shadeLight};
    }
  }

  @media print {
    & thead tr {
      font-size: 0.7rem;
    }
  }

  & thead tr:first-child {
    & th, & td {
      border-top: 2px solid ${ThemeSettings.colors.primary}40;
    }
  }

  @media print {
    & thead tr {
      font-size: 0.7rem;
    }
  }
  
  
  
  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;


export const TextFeedbackTable = styled.table`
  table-layout: fixed;
  width: 100%;

  & .judge-name {
    font-weight: 500;
    color: ${ThemeSettings.colors.shadeLight};
  }

  & td:first-child {
    width: 300px;
  }

  & td:not(:first-child) {
    width: calc((100% - 300px) / 2);
    min-width: calc((100% - 300px) / 2);
  }

  @media print {
    & td:first-child {
      display: none;
    }

    & td:not(:first-child) {
      width: 50%;
      min-width: 50%;
    }
  }
  
  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;



export interface RoundScoresTableProps {
  scores: TeamRoundFeedback;
  config: RoundsFeedbackConfig;
}



export const RoundScoresTable = (props: RoundScoresTableProps) => {
  const { scores, config } = props;

  const { s1Name, s2Name, judges, scoresBySpeakerByJudge } = prepareShapedScores(scores, config);

  return (<>
    <ScoresTable>
      <thead>
        <tr>
          <th></th>
          <th className="speaker-title" colSpan={3}>{s1Name || ""}</th>
          <th className="speaker-title" colSpan={3}>{s2Name || ""}</th>
        </tr>
        <tr>
          <th></th>
          {judges.map((j,i) => (
            <th key={10+i}>
              {j?.first_name || ""} {j?.last_name || ""}
            </th>
          ))}
          {judges.map((j,i) => (
            <th key={20+i}>
              {j?.first_name || ""} {j?.last_name || ""}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {(config.numeric_criteria || []).map(c => (
          <tr key={c._id}>
            <th>{c.name}</th>
            {[0,1,2].map(judgePosition => (
              <td key={`s0_j${judgePosition}`}>
                {(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""] || ""}
              </td>
            ))}
            {[0,1,2].map(judgePosition => (
              <td key={`s1_j${judgePosition}`}>
                {(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""] || ""}
              </td>
            ))}
          </tr>

        ))}
        <tr>
          <th></th>
          {[0,1,2].map(judgePosition => (
              <td key={`s0_j${judgePosition}`}>
                {scoresBySpeakerByJudge[0][judgePosition]?.result}
              </td>
            ))}
          {[0,1,2].map(judgePosition => (
              <td key={`s1_j${judgePosition}`}>
                {scoresBySpeakerByJudge[1][judgePosition]?.result}
              </td>
            ))}
        </tr>
      </tbody>
    </ScoresTable>

    <TextFeedbackTable>
      <td></td>
      <td>
        {[0,1,2].map(judgePosition => {
          return config.text_criteria
            .filter(c => !!(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""])
            .map(c => (
              <p key={`s0_j${judgePosition}_${c._id}`}>
                <span className="judge-name">{judges[judgePosition]?.first_name || `Arbitrator ${judgePosition+1}`} {judges[judgePosition]?.last_name || ""}</span>: {(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""] || ""}
              </p>
            ))})}
      </td>

      <td>
        {[0,1,2].map(judgePosition => {
          return config.text_criteria
          .filter(c => !!(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""])
            .map(c => (
              <p key={`s1_j${judgePosition}_${c._id}`}>
                <span className="judge-name">{judges[judgePosition]?.first_name || `Arbitrator ${judgePosition+1}`} {judges[judgePosition]?.last_name || ""}</span>: {(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""] || ""}
              </p>
            ))})}
      </td>
    </TextFeedbackTable>
  </>)
}
