import React from 'react';
import { ArbitratorRegistration } from './useArbitratorSignup';
import { FormControlsForFields, ValidationErrors } from '../../../../toolympus/components/schemed';
import { Schema } from '../../../../toolympus/hooks/useSchema';
import { FormGrid } from '../../../../toolympus/components/primitives';
import { RadioOptions } from '../../../../toolympus/components/primitives/RadioOptions';

interface Props {
  item: ArbitratorRegistration | null;
  update: (c: Partial<ArbitratorRegistration>) => void;
  schema: Schema;
  errors?: ValidationErrors;
  isFormDisabled?: boolean;  
}

export const ArbitratorSignupForm = (props: Props) => {
  const data = props;
  const { item, update, isFormDisabled } = data;

  if(!item) {
    return null;
  }

  return (
    <>
      <FormGrid columns="1fr 1fr">
        <FormControlsForFields
          data={item}
          fields={[
            ["email", { label: "E-mail" }],
            ["phone", { label: "Phone" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      <FormGrid columns="1fr" noMargin>
        <FormControlsForFields
          data={item}
          fields={[
            ["full_name", { label: "Full name" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      <FormGrid columns="1fr 1fr" noMargin>
        <FormControlsForFields
          data={item}
          fields={[
            ["country", { label: "Country" }],
            ["timezone", { label: "Time Zone" }],
            ["company", { label: "Work place" }],
            ["position", { label: "Work position" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      <FormGrid columns="1fr">
        <FormControlsForFields
          data={item}
          fields={[
            ["affiliation_universities", { autoRows: true, label: "Affiliation with universities participating in the Pre-Moot" }],
            ["affiliation_teams", { autoRows: true, label: "Affiliation as a team coach" }],
            ["moot_experience", { autoRows: true, label: "Moot experience (as an arbitrator, as a coach or as a team member)" }],
            ["comments", { autoRows: true, label: "Other comments" }],
          ]}
          onChange={(o,c) => update(c)}
          schema={data.schema}
          errors={data.errors}
          disabled={isFormDisabled}
          />
      </FormGrid>

      

      <FormGrid columns="1fr" noMargin>
        <RadioOptions
          value={item?.register_for_conference}
          update={v => update({ register_for_conference: v })}
          error={data.errors?.fieldHasErrors("register_for_conference")}
          label={"Do you wish to attend the Pre-Moot's Conference?"}
          options={(data.schema?.register_for_conference?.values || []).map((v,i) => ([v.value, v.label]))}
          readOnly={isFormDisabled}
          />

        <RadioOptions
          value={item?.pdc}
          update={v => update({ pdc: v })}
          error={data.errors?.fieldHasErrors("pdc")}
          label={"According to Federal Law of the Russian Federation dated 27.07.2006 No. 152-FZ \"On Personal Data\", the participants consent to processing of their personal data. (Please note that the team could not participate in the Pre-moot without consent to personal data processing)"}
          options={(data.schema?.pdc?.values || []).map((v,i) => ([v.value, v.label]))}
          readOnly={isFormDisabled}
          /> 
      </FormGrid>      
      

    </>
  );
}
