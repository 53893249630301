import { TeamRoundFeedback, RoundsFeedbackConfig, TeamSpeakerScores } from "../../toolympus/components/Contests/Grid/feedback.types";


const getSpeakerAndScores = (fb: TeamRoundFeedback, config: RoundsFeedbackConfig, idx: number) => {
  const source = fb.scores.scores[idx];
  const scores = source ? source[1] : [];

  const speakerCriteria = config.speaker_criteria;

  const speaker = scores.length && speakerCriteria._id ? scores[0].scores[speakerCriteria._id] : undefined;

  return {
    speaker,
    scores,
  }
}

const getJudges = (fb: TeamRoundFeedback) => {
  const allScores = fb.scores.scores.reduce<TeamSpeakerScores[]>((r,s) => {
    s[1].forEach(x => { r.push(x); });
    return r;
  }, []);

  const judges = [1,2,3].map(position => allScores.find(s => s.judge_position === position)?.judge);

  return judges;
}

const sanitizeSpeakerName = (speaker: any) => {
  const comma = (speaker || "").indexOf(",");
  if(comma >= 0) {
    return speaker.substring(0,comma);
  } else {
    return speaker;
  }
}

export const prepareShapedScores = (fb: TeamRoundFeedback, config: RoundsFeedbackConfig) => {
  const s1 = getSpeakerAndScores(fb, config, 0);
  const s2 = getSpeakerAndScores(fb, config, 1);

  const judges = getJudges(fb);
  
  const scoresBySpeakerByJudge: Record<number, Record<number, TeamSpeakerScores | undefined>> = {};
  [s1,s2].forEach((sx,speakerIdx) => {
    judges.forEach((j,judgeIdx) => {
      if(!scoresBySpeakerByJudge[speakerIdx]) {
        scoresBySpeakerByJudge[speakerIdx] = {};
      }
      scoresBySpeakerByJudge[speakerIdx][judgeIdx] = sx.scores.find(sxx => sxx.judge_position === judgeIdx + 1) || undefined;
    });
  })


  return {
    s1,
    s2,
    s1Name: sanitizeSpeakerName(s1.speaker),
    s2Name: sanitizeSpeakerName(s2.speaker),
    judges,
    scoresBySpeakerByJudge,
  }
}

export type ShapedFeedbackScoresData = ReturnType<typeof prepareShapedScores>;
