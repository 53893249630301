import React, { useMemo } from 'react';
import { useScoredRound } from '../../../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext';
import { ScoringControlProps } from '../../../../toolympus/components/Contests/ScoringSheets';
import { Select } from '../../../../toolympus/components/schemed/Select';
import { FieldType } from '../../../../toolympus/hooks/useSchema';
import { Person } from '../TeamSignup';
import { toMap } from '../../../../toolympus/api/data';


export const SpeakerSelect = (props: ScoringControlProps) => {
    const { data: { scores, update }, criteria } = props;
    const { round, score } = useScoredRound();

    const position = score?.position;
    
    const [speakersOptions,speakersLabels] = useMemo(() => {
      const team = round?._id && position ? round.players[position] : null;
      const speakers = ((round?._id && position && round.info?.members && round.info.members[position]) || []) as Person[];
  
      const options = speakers.map(m => ({ label: m.full_name, value: `${m.full_name}, ${team?.display_name}`}));
      const labels = toMap(options, r => r.value, r => r.label);
      return [options, labels];
    }, [round, position]);

    return (
      <Select
        field="speaker"
        row={{ speaker: scores[criteria?._id || ""]}}
        schema={{
          type: FieldType.select, 
          label: " ",
          values: speakersOptions,
          valueDict: speakersLabels,
        }}
        onChange={(o,c) => update({ [criteria?._id || ""]: c.speaker })}
        />)
}
