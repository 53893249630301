import React from 'react';
import { FrontPageForm, FrontPageHarness } from './FrontPagesCommon';
import { useReturnUrl } from '../../../toolympus/hooks/auth/useReturnUrl';
import { useLoginForm } from '../../../toolympus/hooks/auth';
import { Button, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from '../../../toolympus/components/primitives';
import { useCmsPage } from '../../../toolympus/components/CMS/Usage';

interface Props {
  
}

export const LoginPage = (props: Props) => {
  
  const returnUrl = useReturnUrl();
  const signupData = useLoginForm("/api/login", returnUrl.fromParam || "/");
  const pageConfig = useCmsPage<{ messages: Record<string, any>}>("/api/public/cms/platform_page", "sign-in");

  return (
    <FrontPageHarness
      title={pageConfig.data.messages?.common?.title}
      subtitle={pageConfig.data.messages?.common?.subtitle}>
      <FrontPageForm onSubmit={e => { e.preventDefault(); signupData.send(); }}>
        <Typography variant="h2">{pageConfig.customBlocksData["page_config"]?.title || "Sign in"}</Typography>
        <FormGrid columns="1fr">
          <TextField
            key="email"
            autoFocus
            label={<FormattedMessage id="login.email"/>}
            name='email'
            value={signupData.data.login}
            onChange={e => signupData.update({ ...signupData.data, login: e.target.value || '' })} />
          <TextField
            key="password"
            label={<FormattedMessage id="login.password"/>}
            name='password'
            type='password'
            value={signupData.data.password}
            onChange={e => signupData.update({ ...signupData.data, password: e.target.value || '' })} />

          <Button variant="contained" color="primary" type="submit" style={{ marginTop: "2rem" }}>sign in</Button>
        </FormGrid>
      </FrontPageForm>
    </FrontPageHarness>
  );
}
