import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { prepareShapedScores } from './feedbackHelpers';
import { RoundScoresTableProps } from './RoundScoresTable';

const ScoreBlock = styled.div`
  padding: 0.25rem 0.25rem;
  &:nth-child(2n+1) {
    background: ${ThemeSettings.colors.backgroundAlt1};
  }

  & .criteria-name {
    margin-top: 0;
  }

  &.total {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid ${ThemeSettings.colors.primary}40;

    & .criteria-name {
      font-weight: 500;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
    text-align: center;

    & th {
      width: 50%;
      padding: 2px 8px;

      font-weight: 500;
      color: ${ThemeSettings.colors.shadeLight};
    }

    & tr td {
      width: calc(100% / 6);
      padding: 8px 8px;


      &:nth-child(3) {
        border-right: 2px solid ${ThemeSettings.colors.primary}40;
      }
    }
  }

  & .speaker-name {
    margin: 0;
    & + .speaker-name {
      margin-top: 0.25rem;
    }
  }
`;

export const ScoresTable = styled.div`
  width: 100%;

  @media print {
    display: none;
  }
  
  ${props => props.theme.breakpoints.up("sm")} {
    display: none;
  }
`;


export const TextFeedbackTable = styled.table`
  width: 100%;
  table-layout: fixed;

  & tr td {
    width: 50%;
    vertical-align: top;

    & p {
      margin: 0;
      & + p {
        margin-top: 0.5rem;
      }
    }
  }

  & .judge-name {
    display: block;
    font-weight: 500;
    color: ${ThemeSettings.colors.shadeLight};
  }

  @media print {
    display: none;
  }
  
  ${props => props.theme.breakpoints.up("sm")} {
    display: none;
  }
`;


export const RoundScoresTablePhones = (props: RoundScoresTableProps) => {
  const { scores, config } = props;

  const { s1Name, s2Name, judges, scoresBySpeakerByJudge } = prepareShapedScores(scores, config);

  return (<>
    <ScoresTable>
      {(config.numeric_criteria || []).map(c => (
        <ScoreBlock key={c._id}>
          <p className="criteria-name">{c.name}</p>

          <table>
            <thead>
              <th colSpan={3}>{s1Name}</th>
              <th colSpan={3}>{s2Name}</th>
            </thead>
            <tbody>
              <tr>
                {[0,1,2].map(judgePosition => (
                  <td className="judge-score" key={`s0_j${judgePosition}`}>
                    {(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""] || ""}&nbsp;
                  </td>
                ))}
            
                {[0,1,2].map(judgePosition => (
                  <td className="judge-score" key={`s1_j${judgePosition}`}>
                    {(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""] || ""}&nbsp;
                  </td>
                ))}

              </tr>
            </tbody>
          </table>
        </ScoreBlock>
      ))}

      <ScoreBlock key="total" className="total">
        <p className="criteria-name">Total</p>

        <table>
          <thead>
            <th colSpan={3}>{s1Name}</th>
            <th colSpan={3}>{s2Name}</th>
          </thead>
          <tbody>
            <tr>
              {[0,1,2].map(judgePosition => (
                  <td key={`s0_j${judgePosition}`}>
                    {scoresBySpeakerByJudge[0][judgePosition]?.result}
                  </td>
                ))}
              {[0,1,2].map(judgePosition => (
                  <td key={`s1_j${judgePosition}`}>
                    {scoresBySpeakerByJudge[1][judgePosition]?.result}
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
      </ScoreBlock>
    </ScoresTable>

    <TextFeedbackTable>
      <tbody>
        <tr>
          {[0,1].map(speaker => (
            <td key={speaker}>
              {[0,1,2].map(judgePosition => {
              return config.text_criteria
                .filter(c => !!(scoresBySpeakerByJudge[speaker][judgePosition]?.scores || {})[c._id || ""])
                .map(c => (
                  <p key={`s${speaker}_j${judgePosition}_${c._id}`}>
                    <span className="judge-name">{judges[judgePosition]?.first_name || `Arbitrator ${judgePosition+1}`} {judges[judgePosition]?.last_name || ""}</span>
                    {(scoresBySpeakerByJudge[speaker][judgePosition]?.scores || {})[c._id || ""] || ""}
                  </p>
                ))})}
            </td>
          ))}
        </tr>
      </tbody>
      
      
    </TextFeedbackTable>
  </>)
}
