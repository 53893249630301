import React from 'react';
import { SubjectOutlined } from "@mui/icons-material";
import { CMSMenuWithEditorProps } from "../../toolympus/components/CMS";
import { forAny, setting, suggest } from '../../toolympus/components/PowerDoc/PowerEditorSettingsSuggestions';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PowerEditorBase, PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../../toolympus/components/PowerDoc/plugins/Expander';
import { PanelPlugin } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tiles';

export const CMSIcons = {
  platform_page: <SubjectOutlined />,
}



export const CMSSettingsSuggestions = [
  suggest(forAny(),
    setting("_class")),
  suggest(forAny(),
    setting("display_type")),
  suggest(forAny(),
    setting("open", ["true", "false"])),
];


export const FDIEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
          ]}
          />
  );
}

export const FDIViewer = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
          ]}
          />
  );
}

export const FDICMSProps: CMSMenuWithEditorProps = {
  apiPath: "/api/cms",
  icons: CMSIcons,
  includeMediaLib: true,
  EditorComponent: FDIEditorUnwrapped,
  settingsSuggestions: CMSSettingsSuggestions,
}
