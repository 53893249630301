import React from 'react';
import { FieldSettingsPopupButton, TableForFields, useFields } from '../../../toolympus/components/schemed';
import { ActionRow, ButtonOptionPicker, Dialog, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, useCopyText } from '../../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { DeleteOutlined, GetAppOutlined, Save } from '@mui/icons-material';
import { useArbitratorsList } from './useArbitratorsList';
import { ArbitratorSignupForm } from '../../fdi/FrontPages/ArbitratorSignupForm/ArbitratorSignupForm';
import { TimeslotsSelection } from '../../../toolympus/components/Contests/Timeslots';
import { useContestStateEdit } from '../../ContestControl';

export const AdminArbitratorListPage = () => {
  const data = useArbitratorsList();
  const contestState = useContestStateEdit();
  const copyText = useCopyText();
  
  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "email",
      "phone",
      "full_name",
      "country",
      "timezone",
      "company",
      "position",
      "affiliation_universities",
      "affiliation_teams",
      "timeslots_preference",
      "register_for_conference",
      "created_at",
    ],
    extraSettings: {
      created_at: { utcToLocal: true },
      tags: { label: "Tags" },
      full_name: { cellStyle: { whiteSpace: "nowrap" }},
    },
    storageKey: "__fdi_arbs_list_fields",
    whitelist: [
      "_id",
      "email",
      "phone",
      "full_name",
      "country",
      "timezone",
      "company",
      "position",
      "affiliation_universities",
      "affiliation_teams",
      "moot_experience",
      "comments",
      "timeslots_preference",
      "register_for_conference",
      "pdc",
      "is_excluded",
      "access_code",
      "created_at",
      "tags",
    ],
    outOfSchemaFields: [
      "tags",
    ]
  });

  return (
    <Form fitFullHeight>
      <ActionRow>
        <Typography variant="h2">Arbitrators</Typography>
        <ButtonOptionPicker
          options={data.tabs.tabs.map((([t,label]) => ([label,t])))}
          selected={data.tabs.current}
          setSelected={data.tabs.setCurrent}
          />

        <Typography variant="caption">Total arbitrators: {data.data?.length || 0}</Typography>

        <OccupyFreeSpace />
        {data.isLoading && <LoadingIndicator sizeVariant="m" />}

        <SearchField
            autoFocus
            small
            noButton
            {...data.filter}
            />
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "fdi.core.arbitrators.arbitrators-entities/arbitrator-signed-ip",
          ]}
          />
        <IconButton size="small" onClick={() => data.exportArbitrators()}><GetAppOutlined /></IconButton>
        <FieldSettingsPopupButton fieldsSettings={fields} />
      </ActionRow>
      
      

      <TableForFields
        fields={fields.activeFields}
        sorting={data.sorting}
        data={data.data}
        schema={data.schema}
        fieldElement={f => {
          switch(f) {
            case "email":
              return (row,s,orig) => (
                  <PseudoLink2 border onClick={() => data.review.startEditing({...row})}>{orig}</PseudoLink2>);

            case "timeslots_preference":
              return (row,s,orig) => <>{row.timeslots_preference && row.timeslots_preference.length ? row.timeslots_preference.length : "-"}</>
            case "tags":
              return (row,s,orig) => <>{data.conflicts.displayForRecord(row._id)}</>;
          }
        }}
        />

      <Dialog
        fixedMaxHeight
        isOpen={data.review.isEditing}
        close={() => data.review.cancel()}
        dialogTitle={<>
          <span style={{ whiteSpace: "nowrap" }}>Arbitrator {data.review.item?.full_name || data.review.item?.email || "..."}</span>
          
        </>}
        titleActions={<>
          {data.review.hasChanges && <IconButton size="small" color="primary" onClick={() => data.review.save()}><Save /></IconButton>}
          <Button onClick={() => copyText(`https://fdi.mootcourt.ru/arbitrator-timeslots?id=${data.review.item?._id}&code=${data.review.item?.access_code}`)}>timeslots poll link</Button>
          <Button color={data.review.item?.is_excluded ? "secondary" : undefined} variant={data.review.item?.is_excluded ? "contained" : "text"} onClick={() => data.exclude.run()}>{data.review.item?.is_excluded ? "arbitrator is excluded" : "exclude"}</Button>
          <IconButton size="small" onClick={() => data.remove.run()}><DeleteOutlined /></IconButton>
        </>}
        fullWidth
        maxWidth="lg">

        {data.review.item && <FormGrid columns="1fr" noMargin style={{ alignItems: "end" }}>
          <Typography variant="h4">Tags</Typography>

          {data.conflicts.displayForRecord(data.review.item?._id || "")}

          <Typography variant="h4" style={{ marginTop: "1rem"}}>Time slots</Typography>
          <TimeslotsSelection
            available={contestState.settings.timeslots}
            selected={data.review.item.timeslots_preference}
            update={v => data.review.update({ timeslots_preference: v })}
            />
        </FormGrid>}

        {data.review.item && <>
          <ArbitratorSignupForm
            {...data.review}
            isFormDisabled={false}
            schema={data.schema}
            />
        </>}
      </Dialog>

      {data.conflicts.popup}
    </Form>
  );
}
