import React from 'react';
import { ActionRow, Form, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { ContestStateForm } from './ContestStateForm';
import { ConflictTagManagement } from './ConflictTagManagement';
import { IconButton } from '@mui/material';
import { useContestStateEdit } from './useContestStateEdit';
import { Save } from '@mui/icons-material';
import { TimeslotsConfigurationEditor } from '../../toolympus/components/Contests/Timeslots';
import { TeamsFeedbackManagementForm } from './TeamsFeedbackManagementForm';

export const ContestControlPage = () => {
    const tabs = useTabsState([
        ["contest-state", "Contest State"],
        ["conflict-tags", "Conflict Tags"],
        ["timeslots", "Time Slots"],
        ["feedback", "Teams Feedback"],
    ], "contest-state", "tab");

    const contestData = useContestStateEdit();
    useSaveable(contestData);

    return (
        <Form title="" noTitle>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("contest-state")}>
              <ContestStateForm data={contestData} />
            </TabPanel>

            <TabPanel state={tabs.forTab("conflict-tags")}>
              <ConflictTagManagement />
            </TabPanel>

            <TabPanel state={tabs.forTab("timeslots")}>
              <ActionRow firstItemNoMargin>
                <OccupyFreeSpace />
                <IconButton size="small" color="primary" onClick={() => contestData.save()} style={{ visibility: contestData.hasChanges ? "visible" : "hidden" }}><Save /></IconButton>
              </ActionRow>

              <TimeslotsConfigurationEditor
                value={contestData.settings.timeslots}
                update={v => contestData.update({ timeslots: v })}
                />
            </TabPanel>

            <TabPanel state={tabs.forTab("feedback")}>
              <TeamsFeedbackManagementForm />
            </TabPanel>
        </Form>
    );
}
