import { useMemo, useState } from "react";
import { useCmsPage } from "../../toolympus/components/CMS/Usage";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { ContestState } from "../ContestControl";
import { TeamRoundsFeedback } from "../../toolympus/components/Contests/Grid/feedback.types";

export const useTeamFeedback = () => {
  const pageConfig = useCmsPage<{ messages: Record<string, any>, contest_state: ContestState }>("/api/public/cms/platform_page", "team-feedback");
  const [isAccessError, setIsAccessError] = useState<boolean>(false);

  const [userId,code] = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    return [query.get("user"),query.get("code")];
  }, []);

  const data = useLoadedData<TeamRoundsFeedback>(
    `/api/feedback/team?_id=${userId}&code=${code}`,
    {} as any,
    !!userId && !!code, undefined,
    {
      onError: e => {
        if(e?.response?.status === 403) {
          setIsAccessError(true);
          return true;
        }
      },
    });

  return {
    ...data,
    pageConfig,
    isAvailable: Object.keys(data.data).length !== 0,
    isMissingArguments: !userId || !code || isAccessError,
  }
}
