import { Typography, Button } from '@mui/material';
import React from 'react';
import { Buttons, OccupyFreeSpace } from '../../../../toolympus/components/primitives';
import { FrontPageHarness, FrontPageForm } from '../FrontPagesCommon';
import { useArbitratorSignup } from './useArbitratorSignup';
import { ArbitratorSignupForm } from './ArbitratorSignupForm';

interface Props {
  
}

export const ArbitratorSignupPage = (props: Props) => {
  const data = useArbitratorSignup();
  const { item } = data;

  const isFormDisabled = data.isLoading || data.isSuccess;

  return (
    <FrontPageHarness
      title={data.pageConfig.data.messages?.common?.title}
      subtitle={data.pageConfig.data.messages?.common?.subtitle}
      pageConfig={data.pageConfig.customBlocksData.page_config}>

      {!data.pageConfig.data.contest_state?.arbitrator_registration_allowed && <Typography style={{ whiteSpace: "pre-line", textAlign: "center" }}>
        {data.pageConfig.customBlocksData.page_config?.blocked_message}
      </Typography>}

      {data.pageConfig.data.contest_state?.arbitrator_registration_allowed && item &&
       <FrontPageForm onSubmit={e => { e.preventDefault(); if(!isFormDisabled) { data.save(); } }}>
        <Typography variant="h2">{data.pageConfig.customBlocksData.page_config?.title || "Arbitrator registration"}</Typography>
        
        {data.pageConfig.data.contest_state?.arbitrator_registration_allowed && <Typography style={{ whiteSpace: "pre-line" }}>
          {data.pageConfig.customBlocksData.page_config?.guide}
        </Typography>}

        {data.item && <ArbitratorSignupForm {...data} isFormDisabled={isFormDisabled} />}

        {!data.isSuccess && <Buttons>
          <OccupyFreeSpace />
          <Button variant="contained" color="primary" type="submit">submit</Button>
        </Buttons>}

        {data.isSuccess && <Typography style={{ whiteSpace: "pre-line", textAlign: "center" }}>
          {data.pageConfig.customBlocksData.page_config?.success_message}
          </Typography>}
      </FrontPageForm>}
    </FrontPageHarness>
  );
}
