import React from 'react';
import { useArbitratorTimeSlotsPage } from './useArbitratorTimeSlotsPage';
import { FrontPageForm, FrontPageHarness } from '../FrontPagesCommon';
import { Button, Typography } from '@mui/material';
import { Buttons, OccupyFreeSpace } from '../../../../toolympus/components/primitives';
import { TimeslotsSelection } from '../../../../toolympus/components/Contests/Timeslots';
import { PageViewer } from '../../../CMS/PageViewer';

interface Props {
  
}

export const ArbitratorTimeslotsPage = (props: Props) => {
  const data = useArbitratorTimeSlotsPage();

  return (
    <FrontPageHarness
      title={data.pageConfig.data.messages?.common?.title}
      subtitle={data.pageConfig.data.messages?.common?.subtitle}
      pageConfig={data.pageConfig.customBlocksData.page_config}
      noSpreadContent>

      <FrontPageForm onSubmit={e => { e.preventDefault(); if(!data.data.isSaved) { data.data.save(); } }}>
        <Typography variant="h2">{data.pageConfig.customBlocksData.page_config?.title || "Arbitrator availability"}</Typography>
      
        <PageViewer key={(data.pageConfig?.page as any)?.slug} content={data.pageConfig?.page?.content} />

        {data.data.item && <TimeslotsSelection
          available={data.pageConfig?.data?.contest_state?.timeslots}
          selected={data.data.item?.timeslots_preference}
          update={v => data.data.update({ timeslots_preference: v })}
          disabled={data.data.isSaved}
          />}

        {!data.data.isSaved && <Buttons style={{ marginTop: "2rem" }}>
          <OccupyFreeSpace />
          <Button variant="contained" color="primary" type="submit">submit</Button>
        </Buttons>}

        {data.data.isSaved && <Typography style={{ whiteSpace: "pre-line", textAlign: "center", marginTop: "2rem" }}>
          {data.pageConfig.customBlocksData.page_config?.success_message}
          </Typography>}
      </FrontPageForm>
    </FrontPageHarness>
  );
}
