import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';

import { Providers } from './Providers';
import { ResetPasswordContainer, RestorePasswordContainer } from './components/Auth';
import { LoginPage } from './components/fdi/FrontPages/LoginPage';
import { TeamSignupPage } from './components/fdi/FrontPages/TeamSignup';
import { ArbitratorSignupPage } from './components/fdi/FrontPages/ArbitratorSignupForm';
import { ArbitratorTimeslotsPage } from './components/fdi/FrontPages/ArbitratorTimeslotsPage';
import { ScoreRoundPage } from './components/fdi/FrontPages/Arbitrator/ScoreRoundPage';
import { TeamFeedbackPage } from './components/Team';

const App: React.FC = () => {
    return (
        <Providers>
            <Router>
                <ErrorBoundary>
                    <Switch>
                        <Route path='/team-registration' component={TeamSignupPage} />
                        <Route path='/team/feedback' component={TeamFeedbackPage} />
                        <Route path='/arbitrator-registration' component={ArbitratorSignupPage} />
                        <Route path='/arbitrator-timeslots' component={ArbitratorTimeslotsPage} />
                        <Route path='/score-round' component={ScoreRoundPage} />
                        <Route path='/login' component={LoginPage} />
                        <Route path='/reset' component={ResetPasswordContainer} />
                        <Route path='/restore-password' component={RestorePasswordContainer} />
                        <Route path='/' component={AppRouter} />
                    </Switch>
                </ErrorBoundary>
            </Router>
        </Providers>
    );
}

export default App;