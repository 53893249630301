import React from 'react';
import styled from '@emotion/styled';
import { ActionInstance } from './actions.types';
import { FormGrid } from '../primitives';
import { FormControl, FormControlsForFields } from '../schemed';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../../hooks/useSchema';
import { EmailTemplateSelector } from '../emails';
import { FileCopyOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import { generateCode } from '../PowerDoc/plugins/common';
import { ListEditor, ListWrapper } from '../primitives/ListEditor';
import { copyActionToClipboard, useActionEdit } from './useActionEdit';

interface Props {
  item: ActionInstance;
  update: (c: Partial<ActionInstance>) => void;
  isCollapsed?: boolean;
  setIsCollapsed?: (action: ActionInstance, v: boolean) => void;
}

const Hint = styled(Typography)`
  white-space: pre-line;
`;
Hint.defaultProps = { variant: "caption", color: "textSecondary", component: "p" } as any;

const ActionWrapper = styled(FormGrid)`
  padding-left: 1rem;
  border-left: 3px solid ${props => props.theme.palette.primary.main};
`;

const NestedActionsWrapper = styled(ListWrapper)`
  padding: 0;
`;


export const ActionEditorBase = (props: Props) => {
  const { actionConfig, schema, update, params  } = useActionEdit(props.item, props.update);
  
  return (
    <ActionWrapper columns="1fr 1fr" noMargin>
      <FormGrid columns="1fr" gap="dense" noMargin>
        <FormControlsForFields
          data={props.item}
          onChange={(o,c) => update(c)}
          schema={schema}
          fields={[
            ["action_type"],
          ]}
          />

        {!props.isCollapsed && actionConfig?.description_id &&
          <Hint>
            <FormattedMessage id={actionConfig?.description_id} />
          </Hint>}

        {!props.isCollapsed && !actionConfig?.description_id && actionConfig?.description &&
          <Hint>
            {actionConfig?.description}
          </Hint>}
      </FormGrid>
      
      <FormGrid columns="1fr" gap="dense" noMargin>
        <FormControlsForFields
          data={props.item}
          onChange={(o,c) => update(c)}
          schema={schema}
          fields={[
            ["label", {
              controlProps: {
                endAdornment: props.setIsCollapsed
                  ? <InputAdornment position="end">
                    <Button size="small" color="primary" onClick={() => props.setIsCollapsed && props.setIsCollapsed(props.item, !props.isCollapsed)}>
                      <FormattedMessage id={props.isCollapsed ? "common.show_details" : "common.hide_details"} />
                    </Button>
                  </InputAdornment>
                  : undefined,
              }
            }],
          ]}
          />

        {!props.isCollapsed && (actionConfig?.params || []).map(param =>
          param.special_type === "email_template"
            ? (<EmailTemplateSelector
                field={param.code}
                row={params}
                schema={{ ...param, type: param.type || FieldType.textlong }}
                extraProps={{ autoRows: true }}
                onChange={(o,c) => update({ action_params: { ...params, ...c }})}
                />)
            : (<FormControl
                field={param.code}
                row={params}
                schema={{ ...param, type: param.type || FieldType.textlong }}
                extraProps={{ autoRows: true }}
                onChange={(o,c) => update({ action_params: { ...params, ...c }})}
                />))}
      </FormGrid>
    </ActionWrapper>
  );
}

const ActionChainEditor = (props: Props) => {
  const { actionConfig, schema, update, updateNestedActions, pasteAction } = useActionEdit(props.item, props.update);

  return (
    <ActionWrapper columns="1fr" noMargin>
      <FormGrid columns="1fr 1fr" noMargin>
        <FormControlsForFields
          data={props.item}
          onChange={(o,c) => update(c)}
          schema={schema}
          fields={[
            ["action_type"],
            ["label", {
              controlProps: {
                endAdornment: props.setIsCollapsed
                  ? <InputAdornment position="end">
                    <Button size="small" color="primary" onClick={() => props.setIsCollapsed && props.setIsCollapsed(props.item, !props.isCollapsed)}>
                      <FormattedMessage id={props.isCollapsed ? "common.show_details" : "common.hide_details"} />
                    </Button>
                  </InputAdornment>
                  : undefined,
              }
            }],
          ]}
          />

        {!props.isCollapsed && actionConfig?.description_id &&
          <Hint>
            <FormattedMessage id={actionConfig?.description_id} />
          </Hint>}

        {!props.isCollapsed && !actionConfig?.description_id && actionConfig?.description &&
          <Hint>
            {actionConfig?.description}
          </Hint>}
      </FormGrid>
      
      {!props.isCollapsed && <ListEditor
        idField="_id"
        items={(props.item.action_params?.actions || []) as ActionInstance[]}
        update={v => updateNestedActions(v)}
        wrapperComponent={NestedActionsWrapper}
        itemEditor={ActionEditorBase}
        itemExtraActions={ActionActionButtons}
        createDflt={() => ({
          _id: generateCode(),
          action_params: {},
        })}
        extraActions={<>
          <Button
            size="small"
            startIcon={<InsertDriveFileOutlined />}
            onClick={pasteAction}>
              <FormattedMessage id="common.paste" />
          </Button>
        </>}
        />}
    </ActionWrapper>);
}

export const ActionEditor = (props: Props) => {
  if(props.item.action_type === "toolympus.extras.actions/action-chain") {
    return <ActionChainEditor {...props} />
  } else {
    return <ActionEditorBase {...props} />
  }
}



export const ActionActionButtons = (props: Props) => {
  return <>
    {!props.isCollapsed && <IconButton size="small" onClick={() => copyActionToClipboard(props.item)}><FileCopyOutlined /></IconButton>}
  </>
}
