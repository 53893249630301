import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export const RadioWrapper = styled(FormControl)`
  margin-top: 1rem;
  & .MuiFormLabel-root {
    color: ${props => props.error ? props.theme.palette.error.main : "inherit"};
  }
`;

interface Props {
  label?: ReactNode;
  value?: string;
  update: (v: string) => void;
  readOnly?: boolean;
  error?: boolean;

  options?: [string, ReactNode][];
  wrapperStyle?: React.CSSProperties;
}

export const RadioOptions = (props: Props) => {
  return (
    <RadioWrapper error={props.error} style={props.wrapperStyle}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <RadioGroup row value={props.value} onChange={(e,v) => props.update(v)}>
        {(props.options || []).map(([option,label]) => (
          <FormControlLabel value={option} control={<Radio color="primary" readOnly={!props.readOnly} />} label={label} />
        ))}
      </RadioGroup>
    </RadioWrapper>
  );
}
