import React from 'react';
import { useTeamsFeedbackManagement } from './useTeamsFeedbackManagement';
import { FormGrid, LoadingIndicator } from '../../toolympus/components/primitives';
import { Button } from '@mui/material';

interface Props {
  
}

export const TeamsFeedbackManagementForm = (props: Props) => {
  const { prepare, clear } = useTeamsFeedbackManagement();
  return (
    <FormGrid columns="180px 180px 1fr">
      <Button
        size="small"
        color="primary"
        variant="contained"
        endIcon={prepare.isRunning ? <LoadingIndicator sizeVariant="s" color="inherit" /> : undefined}
        onClick={() => prepare.run()}>
        Prepare feedback
      </Button>

      <Button
        size="small"
        color="secondary"
        endIcon={clear.isRunning ? <LoadingIndicator sizeVariant="s" color="inherit" /> : undefined}
        onClick={() => clear.run()}>
        Clear feedback
      </Button>
    </FormGrid>
  );
}
