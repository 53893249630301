import { useMemo } from "react";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { CMSRecord } from "../types";
import { getCustomBlocksData } from "./BlocksAndLinks";

export const useCmsPage = <ExtraData = {}>(apiPath: string, slug: string) => {
  const pageInfo = useLoadedData<{ item: Partial<CMSRecord> } & ExtraData>(`${apiPath}/${slug}`, { item: {}} as any);
  const customBlocksData = useMemo(() => {
    return getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);    
  }, [pageInfo.data]);

  return {
    data: pageInfo.data,
    page: pageInfo.data.item,
    customBlocksData,
    isLoading: pageInfo.isLoading
  };
}
