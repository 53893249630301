import { useState } from "react";
import { apiFetch } from "../../../../toolympus/api/core";
import { useEditItem2 } from "../../../../toolympus/api/useNewItem"
import { Schema } from "../../../../toolympus/hooks/useSchema";
import { useCmsPage } from "../../../../toolympus/components/CMS/Usage";
import { ContestState } from "../../../ContestControl";

export interface Arbitrator {
  _id: string;
  email: string;
  phone: string;
  full_name: string;
  country: string;
  timezone: string;
  company: string;
  position: string;
  affiliation_universities: string;
  affiliation_teams: string;
  moot_experience: string;
  comments: string;
  timeslots_preference?: string[];
  register_for_conference: string;
  pdc: string;
  is_excluded: string;
  access_code: string;
  created_at: string;
}

export interface ArbitratorRegistration extends Partial<Omit<Arbitrator, "is_excluded" | "access_code" | "created_at" >> {

}

export const useArbitratorSignup = () => {
  const [isSuccess,setIsSuccess] = useState<boolean>(false);
  const data = useEditItem2<ArbitratorRegistration>({
    getApiPath: () => "",
    save: (arbitrator) => {
      return apiFetch<{ }>("/api/arbitrator/signup", "POST", { ...arbitrator })
        .then(() => {
          setIsSuccess(true);
          return arbitrator;
        })
    },
    startWith: {
      email: "",
      phone: "",
    },
    dontResetOnSave: true,
  });


  
  const pageConfig = useCmsPage<{ messages: Record<string, any>, contest_state: ContestState }>("/api/public/cms/platform_page", "arbitrator-registration");

  const schema: Schema = (pageConfig.data as any).schema_arbitrator || {};

  return {
    ...data,
    pageConfig,
    isSuccess,
    schema,
  }
}
