import { apiFetch } from "../../toolympus/api/core"
import { useActionWithConfirmation } from "../../toolympus/api/useAction"

export const useTeamsFeedbackManagement = () => {
  const prepare = useActionWithConfirmation(
    () => apiFetch<{}>("/api/feedback/prepare", "post", null), {
      title: "Prepare feedback sheets",
      confirmationHint: "Existing sheets will be overwritten if these have been prepared previously",
    });

  const clear = useActionWithConfirmation(
    () => apiFetch<{}>("/api/feedback/clear", "post", null), {
      title: "Clear feedback sheets",
      confirmationHint: "Existing feedback sheets will be removed",
    });

  return {
    prepare,
    clear,
  }
}
