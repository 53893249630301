import { apiFetch } from "../../../toolympus/api/core";
import { useAction, useActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useTags } from "../../../toolympus/components/Tags";
import { useTabsState } from "../../../toolympus/components/primitives";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useFieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { useLoadedData, urlWithQuery } from "../../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { Arbitrator } from "../../fdi/FrontPages/ArbitratorSignupForm";
import { exportArbitratorsXLSX } from "./arbitratorsExport";

const ApiPath = "/api/arbitrator/admin";

export const useArbitratorsList = () => {
  const tabs = useTabsState([
    ["all", "All"],
    ["not-excluded", "Active"],
    ["excluded", "Excluded"],
  ], "not-excluded", "tab", "__fdi_arbitrator_list_cur_tab");

  const sorting = useFieldSorting({
    allowedFields: [
      "codename",
      "country",
      "timezone",
      "register_for_conference",
      "previously_participated",
      "university",
      "email",
      "created_at",
    ],
  })
  
  const data = useLoadedData<Arbitrator[]>(urlWithQuery(ApiPath, { view: tabs.current, "order-by": sorting.sort ? `${sorting.sort.field}:${sorting.sort.direction}` : undefined }), []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const filter = useTextFilter<Arbitrator>(a => `${a.full_name} ${a.email} ${a.country} ${a._id}`)
  const filteredData = filter.filterData(data.data);

  const review = useEditItem2<Arbitrator>({
    save: (item,changes) => apiFetch<Arbitrator>(`${ApiPath}/${item._id}`, "put", changes)
      .then(t => {
        data.reload();
        return t;
      }),
    dontResetOnSave: true,
  });

  const exclude = useAction(() => {
    if(!review.item) {
      return Promise.resolve({});
    } else {
      return apiFetch<Arbitrator>(`${ApiPath}/${review.item._id}`, "put", { is_excluded: !review.item.is_excluded})
        .then(x => {
          review.setItem(x);
          data.reload();
          return x;
        })
    }
  });

  const remove = useActionWithConfirmation(() => {
    if(!review.item) {
      return Promise.resolve({});
    } else {
      return apiFetch<Arbitrator>(`${ApiPath}/${review.item._id}`, "delete")
        .then(x => {
          review.cancel();
          data.reload();
          return x;
        })
    }
  }, {
    title: "Remove arbitrator"
  })

  const conflicts = useTags("/api/conflict/tag", "arbitrator");

  const exportArbitrators = () => exportArbitratorsXLSX(data.data, schema, `FDI arbitrators ${new Date().getFullYear()}.xlsx`);

  return {
    ...data,
    data: filteredData,
    schema,
    filter,
    tabs,
    sorting,
    review,
    exclude,
    remove,
    conflicts,
    exportArbitrators,
  }
}
