import React from 'react';
import { useTeamsList } from './useTeamsList';
import { FieldSettingsPopupButton, FormControlsForFields, TableForFields, useFields } from '../../../toolympus/components/schemed';
import { ActionRow, ButtonOptionPicker, Dialog, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, SimpleDialog, Tooltip, useCopyText } from '../../../toolympus/components/primitives';
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { TeamSignupForm } from '../../fdi/FrontPages/TeamSignup/TeamSignupForm';
import { DeleteOutlined, GetAppOutlined, LinkOutlined, Save } from '@mui/icons-material';

export const AdminTeamListPage = () => {
  const data = useTeamsList();
  
  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "email",
      "codename",
      "country",
      "university",
      "timezone",
      "register_for_conference",
      "previously_participated",
      "members",
      "coaches",
      "created_at",
    ],
    extraSettings: {
      created_at: { utcToLocal: true },
      tags: { label: "Tags" },
    },
    storageKey: "__fdi_teams_list_fields",
    whitelist: [
      "_id",
      "email",
      "phone",
      "codename",
      "university",
      "country",
      "timezone",
      "register_for_conference",
      "previously_participated",
      "coaches_as_arbitrators_consent",
      "pdc",
      "members",
      "coaches",
      "is_excluded",
      "rounds_stage",
      "access_code",
      "created_at",
      "tags",
    ],
    outOfSchemaFields: [
      "tags",
    ]
  });

  const copyText = useCopyText();

  return (
    <Form fitFullHeight>
      <ActionRow>
        <Typography variant="h2">Teams</Typography>
        <ButtonOptionPicker
          options={data.tabs.tabs.map((([t,label]) => ([label,t])))}
          selected={data.tabs.current}
          setSelected={data.tabs.setCurrent}
          />

        <Typography variant="caption">Total teams: {data.data?.length || 0}</Typography>

        <OccupyFreeSpace />
        {data.isLoading && <LoadingIndicator sizeVariant="m" />}

        <Button size="small" onClick={() => data.assignCodenames.startEditing({ names: "" })}>assign codenames</Button>

        <SearchField
            autoFocus
            small
            noButton
            {...data.filter}
            />
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "fdi.core.teams.teams-entitites/team-signed-up",
          ]}
          />
        <IconButton size="small" onClick={() => data.exportTeams()}><GetAppOutlined /></IconButton>
        <FieldSettingsPopupButton fieldsSettings={fields} />
      </ActionRow>
      
      

      <TableForFields
        fields={fields.activeFields}
        sorting={data.sorting}
        data={data.data}
        schema={data.schema}
        fieldElement={f => {
          switch(f) {
            case "email":
              return (row,s,orig) => (
                  <PseudoLink2 border onClick={() => data.review.startEditing({...row})}>{orig}</PseudoLink2>);

            case "members":
              return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing({...row})}>{(row.members || []).length}</PseudoLink2>
            case "coaches":
              return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing({...row})}>{(row.coaches || []).length}</PseudoLink2>
            case "tags":
              return (row,s,orig) => <>{data.conflicts.displayForRecord(row._id)}</>;
          }
        }}
        />

      <Dialog
        fixedMaxHeight
        isOpen={data.review.isEditing}
        close={() => data.review.cancel()}
        dialogTitle={<>
          <span style={{ whiteSpace: "nowrap" }}>Team {data.review.item?.codename || data.review.item?.email || "..."}</span>
          
        </>}
        titleActions={<>
          {data.review.hasChanges && <IconButton size="small" color="primary" onClick={() => data.review.save()}><Save /></IconButton>}
          <Button color={data.review.item?.is_excluded ? "secondary" : undefined} variant={data.review.item?.is_excluded ? "contained" : "text"} onClick={() => data.exclude.run()}>{data.review.item?.is_excluded ? "team is excluded" : "exclude"}</Button>
          <IconButton size="small" onClick={() => data.remove.run()}><DeleteOutlined /></IconButton>
        </>}
        fullWidth
        maxWidth="lg">

        {data.review.item && <FormGrid columns="1fr 1fr" noMargin style={{ alignItems: "end" }}>
          <FormControlsForFields
            data={data.review.item}
            schema={data.schema}
            fields={[
              ["codename"],
            ]}
            onChange={(o,c) => data.review.update(c)}
            />

            <div />
          
          <FormControlsForFields
            data={data.review.item}
            schema={data.schema}
            fields={[
              ["_id"],
              ["access_code", {
                controlProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip text="Copy team feedback access link">
                        <IconButton size="small" onClick={() => copyText(`https://fdi.mootcourt.ru/team/feedback?user=${data.review.item?._id}&code=${data.review.item?.access_code}`)}>
                          <LinkOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }
              }],
            ]}
            onChange={(o,c) => {}}
            readOnly
            />
        </FormGrid>}

        {data.review.item && <FormGrid columns="1fr" style={{ alignItems: "end", justifyItems: "start" }}>
          <Typography variant="h4">Tags</Typography>

          {data.conflicts.displayForRecord(data.review.item?._id || "")}
        </FormGrid>}

        {data.review.item && <>
          <TeamSignupForm
            {...data.review}
            schema={data.schema}
            />
        </>}
      </Dialog>

      <SimpleDialog
        dialogTitle="Assign codenames"
        saveLabel="Assign"
        noSubmitOnEnter
        noFullscreen
        isOpen={data.assignCodenames.isEditing}
        close={() => data.assignCodenames.cancel()}
        save={() => data.assignCodenames.save()}>
        <Typography>
          Please enter available code names in the field below, one per line. This will assign a name to every team at random.
        </Typography>
        <Typography>
          If some teams already have codenames, those will be overwritten. Excluded teams won't get codenames assigned.
        </Typography>

        <TextField
          value={data.assignCodenames.item?.names || ""}
          onChange={e => data.assignCodenames.update({ names: e.target.value })}
          multiline
          fullWidth
          />
      </SimpleDialog>

      {data.conflicts.popup}
    </Form>
  );
}
