import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
// import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { ThemeSettings } from './theme';
import { MediaLibProvider } from './toolympus/components/medialib';

const DrawerPaper = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    background-color: ${ThemeSettings.colors.backgroundAlt1};
    color: ${ThemeSettings.colors.backgroundAlt2};
    & .MuiSvgIcon-root {
        color: ${ThemeSettings.colors.backgroundAlt2};
    }
  }
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Log out' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/media">
            {/* <SchemaProvider> */}
            
                        <AppWrapper>
                            <Sidebar
                                top={<Logo />}
                                menu={<Menu menuItems={menuItems} />}
                                bottom={bottom}
                                open={true}
                                onClose={() => { }}
                                drawerPaperProps={{ shadow: true }}
                                drawerPaperComponent={DrawerPaper}
                            />
                            <AppContentSimple fit100Height>
                                <Routes routes={routes} notFoundRedirect="/" />
                            </AppContentSimple>
                        </AppWrapper>
            {/* </SchemaProvider> */}
          </MediaLibProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

